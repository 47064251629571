import {Box, Chip, LinearProgress} from "@mui/material";
import {useCourses, useMq} from "@plumeuk/shapeshift-common/hooks";
import {EnrollInCollectionButton, ProductSummaryTile} from "@plumeuk/shapeshift-common/product";
import {LibraryItemTags} from "@plumeuk/shapeshift-common/library";
import {GroupCurriculum} from "@plumeuk/shapeshift-common/groups";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {LibraryGroupDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useCallback, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {WYSIWYG} from "../../components/WYSIWYG/WYSIWYG";
import {redditPalette} from "../../constants";
import {isGroupCurriculumCourseItem} from "@plumeuk/shapeshift-types";
import CourseItem from "../../components/courseItem/courseItem";
import {IGroupCurriculumItem} from "@plumeuk/shapeshift-types/IGroupCurriculum";
import {Reccomended} from "../../components/reccomended/reccomended";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		"[class*='contentContainer']": {
			padding: 0
		}
	},
	seriesBadge: {
		background: "#EAEEF0",
		marginBottom: "15px",
		borderRadius: "5px"
	},
	groupLibraryProductPage: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	topContainer: {
		position: "relative",
		padding: "60px",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	topContainerContent: {
		zIndex: 5,
		position: "relative"
	},
	topContainerBackground: {
		background: redditPalette.bg,
		position: "absolute",
		top: "-20px",
		left: 0,
		right: 0,
		zIndex: 0,
		height: "100%"
	},
	titleContainer: {
		"& > *": {
			marginBottom: "10px"
		},
		"& h4":{
			marginBottom: "5px"
		}
	},
	title:{
		marginBottom: "25px"

	},
	container: {
		width: "100%"
	},
	content: {
		padding: "60px",
		maxWidth: "1020px",
		margin: "auto"
	},
	tagContainer: {
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse",
			height: "70px",
			justifyContent: "space-between"
		}
	},
	videoPlayer: {
		maxWidth: "900px",
		margin: "auto"
	},
	reccomendedContainer: {
		width: "100%",
		background: redditPalette.white,
		padding: "100px 0"
	},
	curriculum: {
		padding: "60px",
		maxWidth: "1300px",
		margin: "auto",
		background: redditPalette.white,
		[theme.breakpoints.down("sm")]: {
			padding: "20px"
		}

	},
	recommended: {
		marginTop: "30px",
		marginBottom: "200px",
		width: "100%",
		maxWidth: "1300px",
		margin: "auto",
		"& [class*='groupItem']": {
			border: "1px solid " + redditPalette.grey300
		},
		padding: "60px",
		[theme.breakpoints.down("sm")]: {
			padding: "20px"
		}

	},
	summaryTile: {
		background: redditPalette.bg,
		padding: 0,
		"[class*='topContainer']": {
			display: "none"
		},
		"[class*='actionContainer']": {
			padding: 0
		},
		"[class*='groupLibraryProductSummaryTile']": {
			padding: 0,
			background: redditPalette.bg

		},
		"& button": {
			borderRadius: "5px"
		},
		width: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	enrollInCollectionBtn: {
		borderRadius: "5px",
		marginBottom: "25px"
	},
	courseItem: {
		width: "100%",
		maxWidth: "100%",
		transition: "transform 0.3s ease-in-out",
		transform: "scale(1.002)",
		"&:hover": {
			transform: "scale(1.02)"
		},
		"[class*='paper']": {
			borderRadius: "10px"
		}
	},
	libraryItemTags: {
		gap: "10px",
		"& > button": {
			marginRight: 0,
			borderRadius: "500px",
			padding: "0 20px",
			minHeight: "30px",
			background: theme.palette.secondary.main,
			border: "none"
		}
	}
}));

export const GroupProductPage: FC = () => {
	const {classes, cx} = useStyles();
	const {groupSlug} = useParams();
	const navigate = useNavigate();
	const {courses} = useCourses();
	const {notify} = useContext(NotificationContext);
	const mq = useMq();

	const handleGoCurriculumToItemAction = useCallback((e: IGroupCurriculumItem) => {
		if(isGroupCurriculumCourseItem(e)){
			navigate(e.enrolled ? `/learn/course/${e.slug}` : `/discover/collection/${groupSlug}/course/${e.slug}`)
		}
	}, [])

	return (
		<PageBase className={classes.pageBase} contentWidth="100%" disableSideBar>
			<LibraryGroupDataProvider slug={groupSlug}>
				{({group, apiResult}) => {
					const errorMessage = apiResult?.errorData?.error?.message;
					if(errorMessage?.includes("does not meet filter requirements for group")){
						notify("You do not meet the requirements to access this content.", "Content restricted!", INotificationSeverity.error, 15000);
						navigate("/discover")
						return <LinearProgress />
					}

					return (
						<Box className={classes.groupLibraryProductPage}>
							<Box className={classes.topContainer}>
								<Box className={classes.topContainerBackground}></Box>
								<Box className={classes.topContainerContent}>
									<Chip className={classes.seriesBadge} color="default" label="SERIES" size="small" />
									<Box className={classes.container}>
										<PageTitle className={classes.title} title={group?.title} subtitle={group?.description ?? ""} />
										{(group && !group?.enrolled) && <EnrollInCollectionButton
											className={classes.enrollInCollectionBtn}
											onEnrollAction={() => navigate("/my-collection")}
											libraryItem={group}
										>
											{() => <>Enroll in series</>}
										</EnrollInCollectionButton>}
										<Box className={classes.tagContainer}>
											<LibraryItemTags className={classes.libraryItemTags} item={group ? {...group, __type: "plugin::strapi-plugin-shapeshift-cms.group"} : undefined}/>
										</Box>
										{group?.videoUrl && <VideoPlayer
											className={classes.videoPlayer}
											url={group.videoUrl}
											controls={true}
											height={mq.mobile ? "250px" : "550px"}
										/>}
									</Box>
								</Box>
							</Box>
							<WYSIWYG className={classes.content}>{group?.content ?? ""}</WYSIWYG>

							{group?.curriculum && <GroupCurriculum
								className={classes.curriculum}
								data={group.curriculum}
								onGoToItemAction={(e) => handleGoCurriculumToItemAction(e)}
								componentOverrides={{item: item => ({
									courseItem: ({onGoToCertificateAction, ...props}) => <CourseItem
										{...props}
										isEnrolled={isGroupCurriculumCourseItem(item) ? item.enrolled : false}
										className={cx(props.className, classes.courseItem)}
									/>
								})}}
							/>}

							{/* <Reccomended /> */}
						</Box>
					)
				}}
			</LibraryGroupDataProvider>
		</PageBase>
	);
}