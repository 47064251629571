import {Box, LinearProgress, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {ProductSummaryTile} from "@plumeuk/shapeshift-common/product";
import {LibraryItemTags} from "@plumeuk/shapeshift-common/library";
import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {LibraryCourseDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {PageBase} from "../../components/pageBase/pageBase";
import {WYSIWYG} from "../../components/WYSIWYG/WYSIWYG";
import {redditPalette} from "../../constants";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {Reccomended} from "../../components/reccomended/reccomended";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		"[class*='contentContainer']": {
			padding: 0
		}
	},
	libraryProductPage: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	topContainer: {
		background: redditPalette.bg,
		padding: "60px",
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	summaryTile: {
		marginRight: "40px",
		flexShrink: 0,
		marginBottom: "30px",
		width: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		},
		"& [class*='infoContainer']": {
			padding: 0,
			border: "none"
		}
	},
	title:{
		marginBottom: "25px"

	},
	container: {
		width: "100%"
	},
	content: {
		margin: "40px 0"
	},
	tagContainer: {
		"& svg path": {
			fill: theme.palette.common.white
		},
		"& img": {
			filter: "invert(1)"
		},
		display: "flex",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse",
			height: "70px",
			justifyContent: "space-between"
		}

	},
	libraryItemTags: {
		gap: "10px",
		"& > button": {
			marginRight: 0,
			borderRadius: "500px",
			padding: "0 20px",
			minHeight: "30px",
			background: theme.palette.secondary.main,
			border: "none"
		}
	},
	videoPlayer: {
		margin: "40px 0",
		maxWidth: "900px"
	}
}));

export const CourseProductPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, groupSlug} = useParams();
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const mq = useMq();

	return (
		<PageBase className={classes.pageBase} contentWidth="100%" disableSideBar>
			<LibraryCourseDataProvider slug={courseSlug} groupSlug={groupSlug}>
				{({course, apiResult}) => {
					const errorMessage = apiResult?.errorData?.error?.message;
					if(errorMessage?.includes("does not meet filter requirements for course")){
						notify("You do not meet the requirements to access this content.", "Content restricted!", INotificationSeverity.error, 15000);
						navigate("/discover")
						return <LinearProgress />
					}

					if(course && course.enrolled){
						navigate(`/learn/course/${course.slug}`)
						return <LinearProgress />
					}

					return <Box className={classes.libraryProductPage}>
						<Box className={classes.topContainer}>
							<ProductSummaryTile
								libraryItem={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}
								className={classes.summaryTile}
								onEnrollAction={() => navigate(`/learn/course/${course?.slug}`)}
							/>
							<Box className={classes.container}>
								<PageTitle className={classes.title} title={course?.title} subtitle={course?.description} />
								<Box className={classes.tagContainer}>
									<LibraryItemTags className={classes.libraryItemTags} item={course ? {...course, __type: "plugin::strapi-plugin-shapeshift-cms.course"} : undefined}/>
									{course?.instructor && <Typography>Questions? Contact: {course?.instructor}</Typography>}
								</Box>
								{course?.videoUrl && <VideoPlayer
									className={classes.videoPlayer}
									url={course.videoUrl}
									controls={true}
									height={mq.mobile ? "250px" : "550px"}
								/>}
								<WYSIWYG className={classes.content}>{course?.content ?? ""}</WYSIWYG>
							</Box>
						</Box>
						{/* <Reccomended /> */}
					</Box>
				}}
			</LibraryCourseDataProvider>
		</PageBase>
	);
}